html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BB86FC;
  width: 10px;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C060A1;
  width: 15px;
  border-radius: 5px;
}
