@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BB86FC;
  width: 10px;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C060A1;
  width: 15px;
  border-radius: 5px;
}

/* Name, Timeline titles, Buttons etc. */

/* Title */

/* headings */

/* About me text */

/* Inter Gifs Titles */
